var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-supplier",
      "cancel-variant": "secondary",
      "ok-title": "Add",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Supplier"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "disabled": !_vm.isValidForm,
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Simpan ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form', [_c('b-form-group', {
    staticClass: "mb-2"
  }, [_c('label', {
    attrs: {
      "for": "jabatan"
    }
  }, [_vm._v("Nama "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("(*)")])]), _c('b-form-input', {
    attrs: {
      "id": "nama",
      "type": "text",
      "placeholder": "Ex: Budi"
    },
    model: {
      value: _vm.form.nama,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nama", $$v);
      },
      expression: "form.nama"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2"
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Email "), _c('span', {
    staticClass: "text-info"
  }, [_c('i', [_vm._v("(opsional)")])])]), _c('b-form-input', {
    attrs: {
      "id": "email",
      "type": "email",
      "placeholder": "Ex: supplier@gmail.com"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2"
  }, [_c('label', {
    attrs: {
      "for": "nohp"
    }
  }, [_vm._v("No. Handphone "), _c('span', {
    staticClass: "text-info"
  }, [_c('i', [_vm._v("(opsional)")])])]), _c('b-form-input', {
    attrs: {
      "id": "nohp",
      "type": "text",
      "placeholder": "Ex: 08888888888"
    },
    model: {
      value: _vm.form.nohp,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nohp", $$v);
      },
      expression: "form.nohp"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2"
  }, [_c('label', {
    attrs: {
      "for": "alamat"
    }
  }, [_vm._v("Alamat "), _c('span', {
    staticClass: "text-info"
  }, [_c('i', [_vm._v("(opsional)")])])]), _c('b-form-textarea', {
    model: {
      value: _vm.form.alamat,
      callback: function ($$v) {
        _vm.$set(_vm.form, "alamat", $$v);
      },
      expression: "form.alamat"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2"
  }, [_c('label', {
    attrs: {
      "for": "memo"
    }
  }, [_vm._v("Memo "), _c('span', {
    staticClass: "text-info"
  }, [_c('i', [_vm._v("(opsional)")])])]), _c('b-form-textarea', {
    model: {
      value: _vm.form.memo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "memo", $$v);
      },
      expression: "form.memo"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }